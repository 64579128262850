import React from 'react';
import { DefaultProps, PropTypes } from '../../../../core/Utils/SitecoreFields';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';

const CofHeroBanner = ({ fields }) => {
  return (
    <div className={`generic-hero-banner ${fields['css classes']?.value}`}>
      {/* shared header style  */}
      {fields.type.name == 'image banner' && (
        <div className="image-banner">
          <div className="content-wrapper">
            <Image
              className="cover-image"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              field={fields.image}
            />

            <div className="container">
              <div className="content">
                <RichText field={fields.text} />

                {fields?.CTA?.value?.href && (
                  <Link
                    className={`${fields.CTA.value.class}`}
                    field={fields.CTA}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* shared header style  */}
      {fields.type.name == 'title banner' && (
        <div className="title-banner ">
          <div className={`${fields['is fluid'].value ? '' : 'container'} `}>
            <RichText field={fields.text} />
          </div>
        </div>
      )}

      {/* COF specific header style  */}
      {fields.type.name == 'cof case study header' && (
        <div className="cof-case-study-header">
          <div className="title-container container">
            <div className="col-12">
              <RichText field={fields.text} />
            </div>
          </div>

          <div className="image-container">
            <div className="container">
              <Image
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                field={fields.image}
              />

              {fields?.youtubeVID.value && (
                <iframe
                  src={`https://www.youtube.com/embed/${fields.youtubeVID.value}`}
                ></iframe>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default CofHeroBanner;
